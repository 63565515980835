<template>
  <div>
    <p class="h3">Single Giftcard Categories</p>
    <div id="accordion">
      <div v-if="!pageloading">
        <div class="row">
          <div class="col-md-5">
            <div class="card shadow mb-3">
              <div class="card-header" id="headingOne">
                <p class="mb-1 h5 font-weight-normal">
                  Category Name : {{ currentItem.categoryName }}
                </p>
                <p class="mb-1">Details: {{ currentItem.details }}</p>
                <p class="mb-1">Rate: {{ currentItem.rate }}</p>
                <h5 class="mb-0">
                  <div class="d-flex justify-content-end">
                    <button
                      @click="setCurrent(currentItem)"
                      type="button"
                      class="btnd btn-success text-white"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                    >
                      Edit
                    </button>
                  </div>
                </h5>
              </div>
            </div>
            <CreateSubCategory :parentCategory="currentId" />
          </div>
          <div class="col-md-7">
            <div>
              <h5>
                GiftCard Sub Categories
              </h5>
              <div v-for="(item, index) of subCategory" :key="index">
                <div class="card mb-2">
                  <div class="card-body">
                    <p class="mb-0">Name: {{ item.categoryName }}</p>
                    <p class="mb-0">Details: {{ item.details }}</p>
                    <p class="mb-0">Rate: {{ item.rate }}</p>
                    <p class="mb-0">
                      Date Created: {{ item.createdAt | formartDate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p
        v-if="!pageloading && category.length == 0"
        class="h3 text-danger text-center"
      >
        There's No Txn History
      </p>
      <Spinner v-if="pageloading" />
    </div>
    <!-- Button trigger modal -->
    <div
      class="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Edit Category
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="Edit">
              <div class="form-group">
                <label>Category Name</label>
                <input
                  type="text"
                  required
                  class="form-control"
                  v-model="currentEdit.categoryName"
                  placeholder="Enter Category Name"
                />
              </div>
              <div class="form-group">
                <label>Details</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="currentEdit.details"
                  required
                  aria-describedby="emailHelp"
                  placeholder="Enter Details"
                />
              </div>
              <button
                v-if="!loading"
                type="submit"
                class="btnd btn-success text-light"
              >
                Update
              </button>
              <button
                v-else
                class="btnd btn-success text-light"
                type="button"
                disabled
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Calls from "@/Calls";
import Spinner from "@/components/Spinner";
import CreateSubCategory from "./components/CreateSubCategory";
import GiftCardService from "../../services/giftCard.services";
export default {
  name: "AllSupport",
  components: { Spinner, CreateSubCategory },
  data() {
    return {
      currentItem: {},
      category: [],
      subCategory: [],
      pageloading: true,
      currentEdit: {},
      loading: false,
      currentId: "",
    };
  },
  filters: {
    formartNumber(val) {
      return Calls.formartNumber(val);
    },
    formartDate(val) {
      return Calls.formartDate(val);
    },
  },
  methods: {
    async setCurrent(val) {
      this.currentEdit = val;
    },
    async Edit() {
      this.loading = true;
      var message, type;
      await axios
        .put(
          `${Calls.baseUrl()}/admin/giftcard/category?categoryId=${
            this.currentEdit.id
          }`,
          {
            categoryName: this.currentEdit.categoryName,
            details: this.currentEdit.details,
          },
          { headers: JSON.parse(localStorage["headers"]) }
        )
        .then((res) => {
          type = "success";
          message = res.data.msg;
        })
        .catch((err) => {
          type = "error";
          message = err.response.data.msg;
        });

      this.$toast.open({
        message,
        type,
        position: "top-right",
        duration: 2000,
        pauseOnHover: true,
      });
      this.loading = false;
    },
    async fetch() {
      try {
        let response = await GiftCardService.fetchAllCategories();
        this.category = response.data;
      } catch (error) {
        this.$toast.open({
          message: error.msg,
          type: "error",
          position: "top-right",
          duration: 2000,
          pauseOnHover: true,
        });
      }
    },
    async fetchSubCategory(id) {
      await axios
        .get(
          `${Calls.baseUrl()}/giftcard/sub-categories?parentCategory=${id}`,
          { headers: JSON.parse(localStorage["headers"]) }
        )
        .then((res) => {
          this.subCategory = res.data.data.reverse();
        });
    },
  },
  async created() {
    let id = this.$route.params.id;
    this.currentId = id;
    this.fetchSubCategory(id);
    await this.fetch();
    this.category.forEach((item) => {
      if (item.id == id) {
        this.currentItem = item;
      }
    });

    this.pageloading = false;
  },
};
</script>

<style scoped></style>
