<template>
  <div>
    <div class="card">
      <div class="card-body">
        <p class="m-0 p-0 font-weight-bold mb-2">Create Sub Category</p>
        <form @submit.prevent="CreateSubCategory">
          <div class="form-group">
            <label>Category Name</label>
            <input
              type="text"
              required
              class="form-control"
              v-model="categoryName"
              placeholder="Enter Category Name"
            />
          </div>
          <div class="form-group">
            <label>Details</label>
            <input
              type="text"
              class="form-control"
              v-model="details"
              required
              aria-describedby="emailHelp"
              placeholder="Enter Details"
            />
          </div>
          <div class="form-group">
            <label>Rate</label>
            <input
              type="number"
              class="form-control"
              v-model="rate"
              required
              placeholder="Enter Rate"
            />
          </div>
          <button
            v-if="!loadingUpdate"
            type="submit"
            class="btnd btn-success text-light"
          >
            Create
          </button>
          <button
            v-else
            class="btnd btn-success text-light"
            type="button"
            disabled
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Calls from "@/Calls";
import axios from "axios";
export default {
  name: "",
  data() {
    return {
      loadingUpdate: false,
      categoryName: "",
      rate: undefined,
      details: "",
    };
  },
  props: ["parentCategory"],
  methods: {
    async CreateSubCategory() {
      this.loadingUpdate = true;
      var message, type;
      await axios
        .post(
          `${Calls.baseUrl()}/admin/giftcard/category`,
          {
            parentCategory: this.parentCategory,
            categoryName: this.categoryName,
            details: this.details,
            rate: this.rate,
          },
          { headers: JSON.parse(localStorage["headers"]) }
        )
        .then((res) => {
          type = "success";
          message = res.data.msg;
        })
        .catch((err) => {
          type = "error";
          message = err.response.data.msg;
        });

      this.$toast.open({
        message,
        type,
        position: "top-right",
        duration: 2000,
        pauseOnHover: true,
      });

      this.loadingUpdate = false;
      if (type == "success") {
        setTimeout(() => {
          location.reload();
        }, 2100);
      }
    },
  },
};
</script>
